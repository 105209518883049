import React, { useState } from "react";
import Modal from "react-modal";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollection } from "../../hooks/useCollection";

// styles
import styles from "./Home.module.css";

// components
import TransactionForm from "./TransactionForm";
import TransactionList from "./TransactionList";
import { Link } from "react-router-dom";

export default function Home() {
  const { user } = useAuthContext();
  const { documents, error } = useCollection("transactions", [
    "uid",
    "==",
    user.uid,
  ]);

  const { documents: contacts } = useCollection("contacts", [
    "uid",
    "==",
    user.uid,
  ]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

const [section, setSection] = useState('all');

  return (
    <>
      <button className={styles.trbutton} onClick={openModal}>Open Transaction Form</button>
      <div className={styles.tabs}>
        <button className={`${styles.tabLink} ${ section === 'all' ? styles.activeTabLink : ''}`} onClick={() => setSection('all')}>All</button>
        <button className={`${styles.tabLink} ${ section === 'expense' ? styles.activeTabLink : ''}`} onClick={() => setSection('expense')}>Expense</button>
        <button className={`${styles.tabLink} ${ section === 'income' ? styles.activeTabLink : ''}`} onClick={() => setSection('income')}>Income</button>
        <button className={`${styles.tabLink} ${ section === 'lending' ? styles.activeTabLink : ''}`} onClick={() => setSection('lending')}>Lending</button>
        <button className={`${styles.tabLink} ${ section === 'borrowing' ? styles.activeTabLink : ''}`} onClick={() => setSection('borrowing')}>Borrowing</button>
        <button className={`${styles.tabLink} ${ section === 'subscription' ? styles.activeTabLink : ''}`} onClick={() => setSection('subscription')}>Subscription</button>
      </div>
      {section === 'lending' && <Link to="/lending"><button className={styles.trbutton}>Explore Lending Page</button></Link>}
      <div className={styles.container}>
        <div className={styles.content}>
          {error && <p>{error}</p>}
          {documents && <TransactionList transactions={documents} section={section}/>}
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Transaction Form Modal"
        >
          <TransactionForm uid={user.uid} contacts={contacts} closeModal={closeModal}/>
        </Modal>
      </div>
    </>
  );
}
