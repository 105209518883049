import { Link, useParams } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

// styles
import styles from "./Navbar.module.css";

import React, { useEffect, useState } from "react";

const Navbar = () => {
  const { logout } = useLogout();
  const { user } = useAuthContext();

  return (
    <nav className={styles.navbar}>
      <ul>
        <li className={styles.title}>MoneyTransactions</li>

        {!user && (
          <>
            <li className="toggleLogin">
              <Link to="/login">LogIn</Link>
            </li>
            <li className="toggleLogin">
              {/* <Link to="/pranaypisesignup">SignUp</Link> */}
            </li>
          </>
        )}

        {user && (
          <>
            <li>hello, <span className="usernametgg">{user.displayName}</span></li>
            <li className="toggleLogin">
              <button className="btn" onClick={logout}>
                Logout
              </button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
