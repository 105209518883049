import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCollection } from "../../hooks/useCollection";
import styles from '../home/Home.module.css'
import navStyles from '../../components/Navbar.module.css'
import { convertFBTimestampToDate, getAmountRemainingForContact } from "../../utils/conversions";
const ContactTransaction = () => {
  const params = useParams();
  const contactId = params.contactId;

  const { documents } = useCollection("transactions", [
    "uid",
    "==",
    params.uid,
  ]);

  const docsForContact =
    documents?.filter((doc) => doc.contactId === contactId) || [];

  // Function to convert Firestore Timestamp to JavaScript Date object
  const toDate = (timestamp) =>
    new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);

  // Categorize and sort the data
  const categorizedData = docsForContact?.reduce((acc, obj) => {
    const createdAt = toDate(obj.createdAt);
    const monthYear = `${createdAt.toLocaleString("default", {
      month: "long",
    })} ${createdAt.getFullYear()}`;

    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }

    acc[monthYear].push(obj);

    return acc;
  }, {});

  // Sort the categories in descending order
  const sortedCategories =
    categorizedData &&
    Object.keys(categorizedData).sort((a, b) => {
      const dateA = new Date(
        categorizedData[a][0].createdAt.seconds * 1000 +
          categorizedData[a][0].createdAt.nanoseconds / 1000000
      );
      const dateB = new Date(
        categorizedData[b][0].createdAt.seconds * 1000 +
          categorizedData[b][0].createdAt.nanoseconds / 1000000
      );

      return dateB - dateA;
    });

  // Construct the final sorted array
  const sortedData = sortedCategories?.map((category) => ({
    monthYear: category,
    data: categorizedData[category],
  }));

  console.log(sortedData);
  useEffect(() => {
    const toggleLoginElement = document.querySelector("nav");
    if (toggleLoginElement) {
      toggleLoginElement.style.display = "none";
    }
  
  });

  const netBalance = getAmountRemainingForContact(docsForContact);

  return (
    <div>
      <nav className={navStyles.navbar}>
        <div style={{fontSize:25, fontWeight:'bold', textAlign:'center'}}>
            Money Transactions
        </div>
        </nav>
        <div style={{textAlign:'center',marginTop:10}}>Hello, {docsForContact[0]?.lendedTo?.split(' ')[0]}. Following are your transactions</div>

        <div style={{textAlign:'center',marginTop:10, background:netBalance < 0 ? '#2ce02c' : '#ff5e00'}}>
            Net Payable: {netBalance < 0 ? `₹ ${Math.abs(netBalance)} to be paid by Pranay` : netBalance === 0 ? '₹ 0' : `₹ ${netBalance} Remaining to be paid by you`}
        </div>

      {sortedData &&
        sortedData.map((item) => {
          const monthYear = item.monthYear;
          const objects = item.data;
          return (
            <div>
              <h1 style={{textAlign:'center',marginBottom:'-15px'}}>{monthYear}</h1>

              <ul className={styles.transactions}>
                {objects.sort((a, b) => b.createdAt - a.createdAt).map((transaction) => {
                    let desc = '';
                    if(transaction.type === 'lending'){
                      desc = `Taken ₹${transaction.amount} from Pranay on ${convertFBTimestampToDate(transaction.createdAt)} for ${transaction.name}`;
                    }else if(transaction.type === 'borrowing'){
                      desc = `Given ₹ ${transaction.amount} to Pranay on ${convertFBTimestampToDate(transaction.createdAt)}`;
                    }
                    return (
                    <li
                      key={transaction.id}
                      style={{ borderColor: transaction.type === 'lending' ? 'red' : 'green', marginRight:20, justifyContent:'space-between' }}
                    >
                      <div className={styles.name}>
                        <p>{transaction.name}</p>
                        <p className={styles.subtext}>
                          {desc}
                        </p>
                      </div>

                      <div style={{display:'flex', flexDirection:'column'}}>
                      <p className={styles.amount}>Rs {transaction.amount} </p>
                        <p className={styles.subtext}>
                          {convertFBTimestampToDate(transaction.createdAt)}
                        </p>
                      </div>
                      
                    </li>
                  )})}
              </ul>
            </div>
          );
        })}
    </div>
  );
};

export default ContactTransaction;
