import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAXh6xKfesT48t4YQbuYzPHRvj0bkkuFF0",
  authDomain: "personal-data-8243d.firebaseapp.com",
  projectId: "personal-data-8243d",
  storageBucket: "personal-data-8243d.appspot.com",
  messagingSenderId: "1069750520426",
  appId: "1:1069750520426:web:a0162bc7a4979227b81813"
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();

// timestamp
const timestamp = firebase.firestore.Timestamp;

export { projectFirestore, projectAuth, timestamp };
