import { useState } from 'react';
import axios from 'axios';

const usePushbullet = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const accessToken = 'o.gWkY2NefX4VlsYpBbV3vNyVjmR8d66Ja';

  const sendTextMessage = async (phoneNumber, message) => {
    setLoading(true);
    setError(null);

    const data = {
      data: {
        addresses: [phoneNumber],
        message: message,
       target_device_iden: "ujEUP5Z94KasjuQEcTvBng"
    }
    };

    try {
      const response = await axios.post('https://api.pushbullet.com/v2/texts', data, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Token': accessToken
        }
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error('Error:', error);
    }
  };

  return { sendTextMessage, loading, error };
};

export default usePushbullet;
