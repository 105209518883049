// styles
import styles from "./Home.module.css";

export default function TransactionList({ transactions, section }) {
  const getbgColor =(type) =>{
    if(type === 'income'){
      return 'green';
    }else if(type === 'expense'){
      return 'red';
    }else if (type === 'lending'){
      return '#ff5e00';
    }else if (type === 'borrowing'){
      return '#bcbc0f';
    } else if (type === 'subscription'){
      return 'purple';
    }
  }
  const sortAccordingToSection = (section) => {
    if(section === 'all'){
      return transactions;
    }else if(section === 'income'){
      return transactions.filter((transaction) => transaction.type === 'income');
    }else if(section === 'expense'){
      return transactions.filter((transaction) => transaction.type === 'expense');
    }else if(section === 'lending'){
      return transactions.filter((transaction) => transaction.type === 'lending');
    }else if(section === 'borrowing'){
      return transactions.filter((transaction) => transaction.type === 'borrowing');
    }else if(section === 'subscription'){
      return transactions.filter((transaction) => transaction.subscription);
    }
  }
  return (
    <ul className={styles.transactions}>
      {sortAccordingToSection(section).sort((a, b) => b.createdAt - a.createdAt).map((transaction) => (
        <li key={transaction.id} style={{ borderColor: getbgColor(transaction.type)}}>
          <div className={styles.name}>
            <p>{transaction.name}</p>
            <p className={styles.subtext}>{transaction.lendedTo || transaction.expiryDate}</p>

          </div>
          <p className={styles.amount}>Rs {transaction.amount}</p>
        </li>
      ))}
    </ul>
  );
}
