
export const convertFBTimestampToDate = (timestamp) =>{
    const createdAt = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date object
const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    // second: 'numeric',
    hour12: true // Use 12-hour format
};

 return createdAt.toLocaleString('en-US', options);
}

export const getAmountRemainingForContact = (contactTransactions) => {
    const totalAmountLended = contactTransactions.reduce((acc, obj) => {
      if (obj.type === "lending") {
        return acc + parseInt(obj.amount);
      } else if (obj.type === "borrowing") {
        return acc - parseInt(obj.amount);
      }
      return acc;
    }, 0);
    return totalAmountLended;
  };