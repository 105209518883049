import React, { useMemo } from "react";
import { useCollection } from "../../hooks/useCollection";
import { useAuthContext } from "../../hooks/useAuthContext";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import { getAmountRemainingForContact } from "../../utils/conversions";
const Lending = () => {
  const { user } = useAuthContext();
  const { documents, error } = useCollection("transactions", [
    "uid",
    "==",
    user.uid,
  ]);

  const lendingEntries =
    documents
      ?.filter(
        (transaction) =>
          transaction.type === "lending" || transaction.type === "borrowing"
      )
      .sort((a, b) => b.createdAt - a.createdAt) || [];

  const separatedData = lendingEntries?.reduce((acc, obj) => {
    const { contactId } = obj;
    if (!acc[contactId]) {
      acc[contactId] = [];
    }
    acc[contactId].push(obj);
    return acc;
  }, {});

//   const getAmountRemainingForContact = (contactId) => {
//     const contactTransactions = separatedData[contactId] || [];
//     const totalAmountLended = contactTransactions.reduce((acc, obj) => {
//       if (obj.type === "lending") {
//         return acc + parseInt(obj.amount);
//       } else if (obj.type === "borrowing") {
//         return acc - parseInt(obj.amount);
//       }
//       return acc;
//     }, 0);
//     return totalAmountLended;
//   };
  return (
    <div>
      <h1 className={styles.heading}>Lending Page</h1>

      <ul className={styles.transactions}>

        {separatedData &&
          Object.entries(separatedData).map(([contactId, objects]) => {
            const name = objects[0].lendedTo;
            const contactAmount = getAmountRemainingForContact(separatedData[contactId]);
            const createdAt = objects[0].createdAt.toDate(); // Convert Firestore Timestamp to JavaScript Date object
            const options = {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                // second: 'numeric',
                hour12: true // Use 12-hour format
            };

            const lastTransactionAt = createdAt.toLocaleString('en-US', options);

            return (
                <Link to={`/${user.uid}/${contactId}`} key={contactId}>
                <li style={{borderColor: contactAmount < 0 ? 'red' : 'green' }}>
                  <div className={styles.name}>
                    <p>{name}</p>
                    <p className={styles.subtext}>Last: {lastTransactionAt}</p>
                  </div>
                  <p className={styles.amount}>
                    { contactAmount < 0 ? `₹ ${Math.abs(contactAmount)}` : `₹ ${contactAmount}`}
                  </p>
                </li>
                </Link>
            )
          })}
      </ul>
    </div>
  );
};

export default Lending;
