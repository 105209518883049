import React, { useEffect, useState } from "react";
import { useFirestore } from "../../hooks/useFirestore";
import StyledToggle from "../../components/StyledToggle";
import usePushbullet from "../../hooks/usePushBullet";

export default function TransactionForm({ uid, contacts, closeModal }) {
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [type, setType] = useState("expense");
  const  [subscription, setSubscription] = useState(false);
  const  [contactName, setContactName] = useState('');
  const  [contactNumber, setContactNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState(null);
  const [category, setCategory] = useState('personal');
  const [warrenty, setWarrenty] = useState(null);
  const [createUser, setCreateUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addDocument, response } = useFirestore("transactions");
  const { addDocument:addToContacts } = useFirestore("contacts");
  const { sendTextMessage, loading:sendingMessage, error } = usePushbullet();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newTransaction = {
      uid,
      name,
      amount,
      type,
      category,
    };

    if(subscription) {
      newTransaction.subscription = true;
      newTransaction.expiryDate = expiryDate;
    }

    if(warrenty) {
      newTransaction.warrenty = warrenty;
    }

    if(type === "lending" || type === "borrowing") {
      newTransaction.lendedTo = contactName;
      newTransaction.contactNumber = contactNumber;

      newTransaction.contactId = contacts.find(contact => contact.name === contactName).id;
      const message = type === "lending" ? 
      `Hi ${contactName.split(' ')[0]},\nI have Paid Rs. ${amount} for ${name}.\nThanks!\nPranay Pise.\ncheck your Previous transactions on https://transact.pranaypise.com/${uid}/${contacts.find(contact => contact.name === contactName).id}`: 
      `Hi ${contactName.split(' ')[0]},\nI have received Rs. ${amount} for ${name}.\nThanks!\nPranay Pise.\ncheck your Previous transactions on https://transact.pranaypise.com/${uid}/${contacts.find(contact => contact.name === contactName).id}`;      
      
      const stringPhoneNumber = `+91${contactNumber}`;

      await sendTextMessage(stringPhoneNumber, message);
    }
    await addDocument({...newTransaction});
  };

  // reset the form fields
  useEffect(() => {
    if (response.success) {
      setName("");
      setAmount("");
      setType("expense");
      setSubscription(false);
      setContactName(null);
      setContactNumber(null);
      setCategory("personal");
      setWarrenty(null);
      setExpiryDate(null);
      setLoading(false);
      closeModal();
    }
  }, [response.success, closeModal]);

  useEffect(() => {
    if(contacts.find((contact) => contact.name === contactName)?.number) {
      setContactNumber(contacts.find((contact) => contact.name === contactName)?.number);
    }else{
      setContactNumber('');
    }
  }, [contactName, contacts]);

  const handleAddUser = (e) => {
    e.preventDefault();
    if(contactName && contactNumber){
      addToContacts({
       uid,
       name: contactName,
       number: contactNumber,
     });
     setCreateUser('created');
    }else{
      alert('Please enter contact name and number');
    }
  }
  return (
    <div className="transactionform">
      {sendingMessage && <div className="loaderInmodal">
      <div className="loader">
  <span className="bar" />
  <span className="bar" />
  <span className="bar" />
</div>

        </div>}
      <h3>Add a Transcation</h3>
      <form onSubmit={handleSubmit}>

      <label>
          <span>Amount (Rs):</span>
          <input
            type="number"
            required
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
          />
        </label>

        <label>
          <span>Transaction Name:</span>
          <input
            type="text"
            required
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </label>

        <label>
          <span>Type:</span>
          <select value={type} onChange={(e) => setType(e.target.value)} required>
            <option value="income">Income</option>
            <option value="expense">Expense</option>
            <option value="invest">Invest</option>
            <option value="lending">Lending</option>
            <option value="borrowing">Borrowing</option>
            <option value="donation">Donate / Don't think / Forget</option>
          </select>
        </label>

        {(type === "lending" || type === "borrowing") && <label>
          <span>{type === "lending" ? "Lending To" : "Given Back By"}:</span><br />
          <select value={contactName} onChange={(e) => setContactName(e.target.value)}>
            {contacts && contacts.map((contact) => (
              <option key={contact.id} value={contact.name}>
                {contact.name}
              </option>
            ))}
          </select>
        </label> }
        {(createUser && createUser !== 'created')  && <>
           <input
           type="text"
           onChange={(e) => setContactName(e.target.value)}
           placeholder="Add new contact"
           required
           value={contactName}
           onClick={() => setCreateUser(true)}
         />
         <input
           type="number"
           onChange={(e) => setContactNumber(e.target.value)}
           placeholder="Contact Number"
           required
           value={contactNumber}
         />
        </>
        }
        {(type === "lending" || type === "borrowing") && !createUser ? <button onClick={(e) => {e.preventDefault();setCreateUser(true);}}>Add a new User</button>:<button style={{width:'100%', marginTop:10, padding:5, display: (createUser === 'created' || !createUser) ? 'none' : 'block'}} onClick={handleAddUser}>Add User</button>}

        <label>
          <span>Category:</span>
          <select value={category} onChange={(e) => setCategory(e.target.value)} required>
            <option value="business">Business</option>
            <option value="personal">Personal</option>
          </select>
        </label>

        <label style={{display:'flex', gap:10, alignItems:'center'}}>
          <StyledToggle checked={subscription} onChange={() => setSubscription(!subscription)} name={'subscription'}/>
          <span>Is Subscription</span>
        </label>

        {subscription && <label>
          <span>Expiry Date:</span>
          <input
            type="date"
            onChange={(e) => setExpiryDate(e.target.value)}
            value={expiryDate}
          />
        </label>}

        <label style={{display:'flex', gap:10, alignItems:'center'}}>
          <StyledToggle checked={warrenty} onChange={() => setWarrenty(!warrenty)} name={'warrenty'}/>
          <span>Have Warrenty</span>
        </label>

        {warrenty && <label>
          <span>Warrenty Upto:</span>
          <input
            type="date"
            onChange={(e) => setWarrenty(e.target.value)}
            value={warrenty}
          />
        </label>}

        <input type="submit" value="Add Transaction"></input>
      </form>
    </div>
  );
}
